import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'

export const remove = () =>
  http
    .delete('customer/accounts')
    .then(() => true)
    .catch(async ({ response, message }: any) => {
      await errorHandler(response, message)
      return false
    })
