<template>
  <button
    type="button"
    class="mt-3 w-32 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
    @click="openDialog"
    :disabled="disabled"
    :class="{ disable: disabled }"
  >
    {{ t('Cancel CUSTOMER PORTAL') }}
  </button>
  <div
    v-if="dialog"
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-red-500 text-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                id="modal-title"
                class="text-lg leading-6 font-medium text-white border-b-2"
              >
                {{ t('Cancel CUSTOMER PORTAL') }}
              </h3>
              <p class="text-base text-white mt-2">
                {{
                  t(
                    'Removing all registered accounts from CUSTOMER PORTAL. You can not login after removing accounts. Are you sure?'
                  )
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            class="mt-3 w-32 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="cancel"
          >
            {{ t('Yes') }}
          </button>
          <button
            type="button"
            class="mt-3 w-32 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            @click="closeDialog"
          >
            {{ t('No') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { get as getServices, Service } from '@/api/customer/service/list'
import { remove as removeAccounts } from '@/api/customer/accounts'
import { defineComponent, ref, onBeforeMount, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import { get as getStatus } from '@/api/customer/status'
import { signOut } from '@/functions/side-effects/auth'
import { startSingleSignOut } from '@/auth'

export default defineComponent({
  setup() {
    const router = useRouter()
    const items = reactive<{ services: Service[] }>({ services: [] })
    const services = ref(items.services)
    const selected = ref('')
    const notFoundService = ref('')
    const dialog = ref(false)
    const disabled = ref(true)
    const redirectToOrderQtdns = process.env.VUE_APP_REDIRECT_TO_ORDER_QTDNS
    const { t } = useTranslation()
    onBeforeMount(() => {
      getStatus()
        .then((res) => (disabled.value = !res))
        .catch(() => (disabled.value = false))
    })

    const openDialog = () => {
      dialog.value = true
    }

    const closeDialog = () => {
      selected.value = ''
      dialog.value = false
    }

    const cancel = async () => {
      const result = await removeAccounts()

      if (result === true) {
        startSingleSignOut()
        return
      }
      dialog.value = false
    }

    return {
      dialog,
      services,
      redirectToOrderQtdns,
      notFoundService,
      disabled,
      t,
      cancel,
      openDialog,
      closeDialog,
    }
  },
})
</script>
<style scoped lang="postcss">
.disable {
  @apply text-gray-300 hover:bg-white focus:outline-none;
}
</style>
