
import * as Sentry from '@sentry/browser'
import { get as getServices, Service } from '@/api/customer/service/list'
import { remove as removeAccounts } from '@/api/customer/accounts'
import { defineComponent, ref, onBeforeMount, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useTranslation } from '@/locales'
import { get as getStatus } from '@/api/customer/status'
import { signOut } from '@/functions/side-effects/auth'
import { startSingleSignOut } from '@/auth'

export default defineComponent({
  setup() {
    const router = useRouter()
    const items = reactive<{ services: Service[] }>({ services: [] })
    const services = ref(items.services)
    const selected = ref('')
    const notFoundService = ref('')
    const dialog = ref(false)
    const disabled = ref(true)
    const redirectToOrderQtdns = process.env.VUE_APP_REDIRECT_TO_ORDER_QTDNS
    const { t } = useTranslation()
    onBeforeMount(() => {
      getStatus()
        .then((res) => (disabled.value = !res))
        .catch(() => (disabled.value = false))
    })

    const openDialog = () => {
      dialog.value = true
    }

    const closeDialog = () => {
      selected.value = ''
      dialog.value = false
    }

    const cancel = async () => {
      const result = await removeAccounts()

      if (result === true) {
        startSingleSignOut()
        return
      }
      dialog.value = false
    }

    return {
      dialog,
      services,
      redirectToOrderQtdns,
      notFoundService,
      disabled,
      t,
      cancel,
      openDialog,
      closeDialog,
    }
  },
})
