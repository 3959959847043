import { errorHandler } from '@/api/utils'
import { http } from '@/plugins/ky'

export const get = () =>
  new Promise<boolean>((resolve, reject) =>
    http
      .get('customer/status')
      .then(async (r: any) => {
        const result = await r.json()

        resolve(result.removable)
      })
      .catch(async ({ response, message }: any) => {
        await errorHandler(response, message)

        reject(false)
      })
  )
